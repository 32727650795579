import cuid from 'cuid'
import { jwtDecode } from 'jwt-decode'
import { NullOrUndefined } from '../types'

const parseSubdomain = (hn: string): string => (hn.split('.').length > 1 ? hn.split('.')[0] : '')

const addCommasToLargeNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const dashifyString = (string: string) => string.toLowerCase().replace(/\s+/g, '-')

const generateCuid = () => cuid()

const removeExtraSlashes = (str: string) => {
  return str.replace(/\/+/g, '/')
}

const isLastCharacterSlash = (str: string): boolean => str.charAt(str.length - 1) === '/'

const removeLeadingSlash = (str: string) => {
  return str.replace(/^\//, '')
}

// https://datatracker.ietf.org/doc/html/rfc4180#page-2
// https://stackoverflow.com/questions/566052/can-you-encode-cr-lf-in-into-csv-files
const encodeCRLF = (input: string) => {
  if (input == null) return ''

  // Replace any double quotes with two double quotes
  const escaped = input.replace(/"/g, '""')

  // Enclose the field in double quotes
  // This preserves newlines and commas within the field
  return `"${escaped}"`
}

const encodeObjectProps = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce(
    (acc, key) => {
      // Only encode string values
      acc[key] = typeof obj[key] === 'string' ? encodeCRLF(obj[key]) : obj[key]
      return acc
    },
    {} as Record<string, any>,
  )
}

const decodeJwtToken = <T>(token: string | NullOrUndefined): T | null => {
  try {
    if (token == null) return null
    const decodedJwtPayload = jwtDecode<T>(token)
    return decodedJwtPayload
  } catch (error) {
    return null
  }
}

const shouldTruncateText = (text = '', maxLength: number) => {
  return text.length > maxLength
}

export {
  parseSubdomain,
  addCommasToLargeNumber,
  dashifyString,
  generateCuid,
  removeExtraSlashes,
  removeLeadingSlash,
  isLastCharacterSlash,
  encodeCRLF,
  encodeObjectProps,
  decodeJwtToken,
  shouldTruncateText,
}
